.infoUserPadre{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 555;
    margin: 2rem;
    color: $globalColor;
    text-align: center;
    padding: .6rem 1.2rem; 
    border-radius: .3rem;
    box-shadow: 0px 0px 13px 4px rgba(173, 173, 173, 0.356);

    p{
        cursor: default;
        user-select: none;
        margin-bottom: .6rem;
        font-weight: 500;
    }
    button{
        display: block;
        width: fit-content;
        margin: 0 auto;
        padding: 6px;
        border: 1px solid $greyLight;
        color: $globalColor;
        background-color: $background;
        text-decoration: none;
        transition: all .3s ease-in-out;
        &:hover{
            background-color: $globalColor;
            color: $background;
        }
    }
}