.searchBox {

    .emails{
        background-color: $background;
        color: $white;
        margin-right: 4vh;
        margin-left: 2vh;

        &:hover{ border: 1px dashed $white; }
        &:focus{ border: 1px solid $globalColor; }
    }
    textarea {
        resize:vertical;
        height: 5vh;
        padding: 0.5rem 1rem;
    }
    select {
        cursor: pointer;
        font-size: 2vh;
        width: 20vh;
        height: 5vh;
    }
}

.logoImg {
    height: 20vh;
    margin: 3vh 2vh 1vh 2vh;
}
.logosBox {
    display: grid;
    grid-template-columns: 40% 60%;
    color: $white;
    align-items: center;
    

    p {
        font-size: 4vh;
        line-height: 20pt;
    }
    span { font-size: 2vh; line-height: 2pt; }
}

.dateContainer {
    display: grid !important;
    grid-template-columns: 60% 26% !important;
    gap: 6%;
    // grid-template-columns: repeat(2, 1fr) !important;

    label {
        border: $globalColor 2px solid;
        border-radius: 5px;
        padding: 2vh;
    }

    input { cursor: pointer;}
}

.selects {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    justify-content: space-between;

    .convText { width: 15vh; }

    .toHide {
        pointer-events: none;
        opacity: 0.5;
} }
    
.form{
   display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: $white;
    justify-content: space-between;
    margin: 3rem;
    height: 80vh;
    
    div {
        display: grid;
        grid-template-columns: 12% 80%;
        align-items: center;
        text-align: center;
       

        p {
            border: 2px solid $globalColor;
            border-radius: 5px;
            padding: 1vh;
            margin-right: 2vh;
            font-size: 2vh;
        }

        input {
            background-color: $background;
            border-bottom: 1px solid $white;
        }
        input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator{
            cursor: pointer;
            margin-right: 2px;
            opacity: 0.9;
            filter: invert(0.9);
        }
    }

    .reportsBox {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: auto 1vh;
        
        .reports {
            border: none;
            text-align: left;
            margin-left: 4vh;
            width: 50vh;
            margin-top: -1.5vh;
        }

    }

    .title{
        text-align: center;
    }
    input {
        outline: none;
        border: none;
        margin: .8rem .3rem;
        padding: .5rem 1rem;
        font-size: 1.05rem;
        resize: none;
        transition: all .3s ease-in-out;
        color: $white;
        
        &:hover{ border-bottom: 1px dashed $white; }
        &:focus{ border-bottom: 1px solid $globalColor; }
    }
    
    
    button{
        cursor: pointer;
        margin: 1rem auto;
        padding: .5rem 1rem;
        border: none;
        border-radius: .3rem;
        grid-column: 1 / 3;
        align-items: center;
        width: 38vh;
        height: 6vh;
        font-weight: bold;
        font-size: 3vh;
        background-color: $globalColor;
        transition: all .3s ease-in-out;

        &:hover{
            color: $globalColor;
            border: 4px solid $globalColor;
            background-color: $background;
        }
    }
}
.form.row{
    z-index: 999999999999999999999999999999999999;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

/* SQUARED ONE */
.squaredOne {
    width: 28px;
    height: 28px;
    background: $background;
    border:4px solid $globalColor;
    border-radius: 5px;

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
    position: relative;
}

.squaredOne label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1px;
    top: 1px;
}

.squaredOne label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #00bf00;
    top: -1px;
    left: -1px;

    -webkit-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
}

.squaredOne label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.squaredOne input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
/* SQUARED ONE */

.squaredTwo {
    @extend .squaredOne;
}

.squaredThree {
    @extend .squaredOne;
}
.squaredFour {
    @extend .squaredOne;
}