.home {

    .homeContainer{
        width: 60%;
        margin: 8rem auto;
        display: flex;
        flex-direction: column;
    
        img{
            width: 60vh;
            background-color: $globalColor;
            padding: 3vh;
            margin: 0 auto;
        }
        .links{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 4px;

            a{
                
                margin: 4px;
                padding: .6rem 1rem;
                font-size: 1.1rem;
                font-weight: 500;
                text-align: center;
                border: 3px solid $greyLight;
                color: $globalColor;
                background-color: $background;
                width: 20vh;
                transition: all .3s ease-in-out;

                &:hover{
                    background-color: $globalColor;
                    color: $background;
                }
            }        
        }
    }
}
