.toastPadre{
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 350;
    .card{
        cursor: default;
        margin: 1rem;
        box-shadow: 0px 0px 23px 4px rgba(163, 163, 163, 0.474);
        border-radius: .3rem;
        .header{
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
            border-top-left-radius: .3rem;
            border-top-right-radius: .3rem;
            p{
                background-color: $globalColorDark;
                margin-bottom: -.1rem;
            }
            svg{
                background-color: $globalColorDark;
                font-size: 1.3rem;  
                margin-right: .7rem;  
            }
        }
        .body{
            padding: .5rem 1rem;
            border-bottom-left-radius: .3rem;
            border-bottom-right-radius: .3rem;
        }
    }
    //danger
    .danger{
        box-shadow: 0px 0px 18px 4px $globalColorDark;
        .header{
            background-color: $globalColorDark;
        }
        .body{
            background-color: $globalColor;
        }
    }
    //warning
    .warning{
        box-shadow: 0px 0px 18px 4px rgba(196, 144, 0, 0.205);
        .header{
            background-color: rgb(139, 102, 0);
        }
        .body{
            background-color: rgb(196, 143, 0);
        }
    }
    //success
    .success{
        box-shadow: 0px 0px 18px 4px rgba(61, 194, 0, 0.192);
        .header{
            background-color: rgb(1, 65, 1);
        }
        .body{
            background-color: rgb(0, 119, 0);
        }
    }
}