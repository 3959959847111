.loginPadre{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $background;
    .letterImg {
        background-image: url('../../assets/letter.jpg');
        height: 86vh;
        width: 70vh;
        background-position: 70% 40%;
        margin: 4vh 2vh 4vh 10vh;        
    }
    .borderImg {
        border: 4px solid $white;
        margin: 4vh 6vh 4vh 3vh;
        height: 86vh;
        width: 70vh;
    }
    .loginBox {
        display: grid;
        margin-left: 6vh;
        height: 50vh;

        .globalImg {
            width: 40vh;
        }
        .textTitle {
            color: $white;
            font-weight: bold;
            font-size: 12vh;
        }
        .textDescription {
            color: $white;
            font-size: 3vh;
            margin-top: -6vh;
            letter-spacing: 2px;
        }
        .loginButton{
            cursor: pointer;
            align-items: center;
            width: 38vh;
            height: 6vh;
            font-weight: bold;
            font-size: 3vh;
            background-color: $globalColor;
        }
    }
}