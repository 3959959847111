//Modal Button
.modalButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 155;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    font-weight: 500;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(192, 192, 192);
    padding: .8rem 1rem;
    margin: 1.5rem;
    color: rgb(59, 59, 59);
    border-radius: .3rem;
    transition: all .3s ease-in-out;
    p{
        margin-right: 1rem;
    }
    svg{
        transition: all .3s ease-in-out;
    }
    &:hover{
        border-color: rgb(56, 56, 56);
        color: black;
        box-shadow: 0px 0px 14px 3px rgba(104, 104, 104, 0.322);
        svg {
            transform: scale(1.3) rotate(45deg);
        }
    }
}
//Modal Container
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
        cursor: default;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        min-width: 22rem;
        width: fit-content;
        height: fit-content;
        border-radius: 0.5rem;
        background-color: white;
        box-shadow: 0px 0px 23px 4px rgba(160, 157, 157, 0.356);
        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 2rem;
            color: white;
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            background-color: #a90533;
            h2 {
                user-select: none;
                margin-right: 2rem;
                font-size: 1.2rem;
                font-weight: 600;
                span {
                    color: rgba(0, 0, 0, 0.781);
                }
            }
            svg {
                cursor: pointer;
                font-size: 1.6rem;
                transition: all .3s linear;
                z-index: 999;
                &:hover {
                    filter: drop-shadow(0px 0px 4px #FFFFFF);
                    color: white;
                    animation: faTimesAnimate 3s infinite linear;
                }
            }
        }

        .modal-body {
            padding: 2rem 4rem;
            max-height: 40rem;
            img {
                max-width: 30rem;
            }
        }
    }
}

//Animation for Modal faTimes Button
@keyframes faTimesAnimate {
    0% {
        transform: rotate(0deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1.2);
    }
}
