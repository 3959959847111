.backButtonPadre{
    position: fixed;
    top: 0;
    left: 0;
    top: 100;
    margin: 1rem;
    background-color: rgb(233, 233, 233);
    width: 3rem;
    height: 3rem;
    border-radius: .3rem;
    font-size: 1.2rem;
    color: rgb(68, 68, 68);
    border: 2px solid rgb(201, 201, 201);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    &:hover{
        border-color: rgb(54, 54, 54);
        color: rgb(54, 54, 54);
        box-shadow: 0px 0px 13px 4px rgba(139, 139, 139, 0.342);
    }
}