.formCreatePadre{
    display: flex;
    flex-direction: column;
    input{
        width: 20rem;
        margin: 0 auto;
        font-size: 1rem;
        padding: .5rem 1rem;
        border-radius: .3rem;
        outline: none;
        border: 2px solid rgb(211, 211, 211);
        color: rgb(44, 44, 44);
        transition: all .3s ease-in-out;
        &:hover{
            border-color: rgb(104, 104, 104);
            box-shadow: 0px 0px 13px 4px rgba(170, 170, 170, 0.432);
        }
    }
    .universities{
        padding: 1rem;
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        gap: 2rem;
        img{
            cursor: pointer;
            border: 2px solid transparent;
            width: 10rem;
            margin: 0 auto;
            scale: .8;
            transition: all .3s ease-in-out;
        }
        img.active{
            scale: 1;
        }
    }
    button[type="submit"]{
        cursor: not-allowed;
        width: fit-content;
        margin: 2rem auto;
        margin-bottom: 1rem;
        padding: .8rem 1rem;
        border-radius: .2rem;
        font-size: 1rem;
        font-weight: 500;
        color: rgb(182, 182, 182);
        border: 2px solid transparent;
        background-color: rgb(241, 241, 241);
    }
    button[type="submit"].active{
        cursor: pointer;
        color: rgb(63, 63, 63);
        border-color: rgb(121, 121, 121);
        transition: all .3s ease-in-out;
        &:hover{
            color: black;
            border-color: rgb(56, 56, 56);
            box-shadow: 0px 0px 18px 4px rgba(190, 190, 190, 0.445);
        }
    }
}