*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}
*, *::after, *::before{
    box-sizing: border-box;
}
button{
    cursor: pointer;
    background-color: white;
    outline: none;
    border: none;
}
h1{
    text-align: center;
    color: rgb(70, 70, 70);
    margin: 3rem 0;
}