.loginPage{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginContainer{
        display: grid;
        // box-shadow: 8px -8px 5px 0px #7d7d7d94;
        box-shadow: 5px 4px 12px 0px rgba(204, 195, 195, 0.75);
        // box-shadow: -1px -1px 19px 7px rgba(255,193,7,0.67);

        .logo {
            width: 60vh;
            padding: 3vh;
            // background-color: $globalColor;
            border: 2px solid $globalColor;
        }
    }

    button{
        margin-top: 4px;
        padding: .6rem 1rem;
        font-size: 1.1rem;
        font-weight: 500;
        border: 3px solid $greyLight;
        color: $globalColor;
        background-color: $background;
        transition: all .3s ease-in-out;
        &:hover{
            // border-color: $globalColor;
            background-color: $globalColor;
            color: $background;
        }
    }
}