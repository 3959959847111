.exams {
    width: 73%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dateContainer {
    display: grid !important;
    grid-template-columns: 60% 26% !important;
    gap: 6%;
   

    label {
        border: $globalColor 2px solid;
        border-radius: 5px;
        padding: 2vh;
        color: $white;
        text-align: center;
        margin-left: 3vh;
    }

    input {
        border: none;
        color: $white;
        background-color: $background;
        border-bottom: 1px solid $white;
    }
    input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator{
        cursor: pointer;
        margin-right: 2px;
        opacity: 0.9;
        filter: invert(0.9);
    }
}

.infoBox {
    display: flex;
    color: $white;
    align-items: center;
    margin: 4vh 0vh;
    
    img { height: 14vh;}
    
    &-title {
        border: 2px solid $globalColor;
        border-radius: 5px;
        padding: 2vh  4vh;
        margin-left: -7.4vh;
    }
}

.reportsBox {
    width: 50%;
    margin-left: 4vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .reports {
        width: 55vh;
        margin-left: 5vh;
        margin-top: -2vh;
    }

    .squaredOne label {
        background-color: $background;
    }
}

.toEmailBox {
    display: flex;
    color: $white;
    align-items: center;

    img { height: 14vh;}

    input {
        border: none;
        color: $white;
        background-color: $background;
        border-bottom: 1px solid $white;
        height: 6vh;
        width: 25%;
        padding: 2vh;

        &:hover {
            border: 2px solid $white;
            border-radius: 5px;
        }
    }
    .infoBox-title-to {
        @extend .infoBox-title;
        margin-left: 4vh;
        margin-right: 4vh;
    }
}

.button {
    float: right;
    margin-right: 8vh;
    cursor: pointer;
    padding: .5rem 1rem;
    border: none;
    border-radius: .3rem;
    width: 38vh;
    height: 6vh;
    font-weight: bold;
    font-size: 3vh;
    background-color: $globalColor;
    transition: all .3s ease-in-out;

    &:hover{
        color: $globalColor;
        border: 4px solid $globalColor;
        background-color: $background;
    }
}

/*
    @media Querys
  * Móvil versión VERTICAL @media(max-width: 500px){}
  * Movil versión HORIZONTAL. @media screen and (min-width:601px) and (max-width: 890px) and (min-height:320px) and (max-height: 440px){}
  * tablet, landscape iPad, laptops VERTICAL @media screen and (min-width:800px) and (max-width: 840px) and (min-height:990px) and (max-height: 1200px) {}
  * tablet, landscape iPad, laptops HORIZONTAL @media screen and (min-width:990px) and (max-width: 1200px) {}
  * Hi-res desktops - big window. @media (min-width: 1581px) {}
*/

/* Hi-res desktops - big window.*/ 
@media (min-width: 1581px) {
    .exams { width: 60%; }
}